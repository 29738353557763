import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getBrand } from "../services/getBrand";
import { ImageLink } from "../constants";
import { toast } from "react-toastify";

const Section5 = ({ settingData }) => {
  const [brandData, setBrandData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBrand();
        setBrandData(response.data);
      } catch (error) {
        toast.error("Error fetching brand data:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    loop: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="brand-area section-pt-80 section-pb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="brand-title">
              {settingData?.brandTitle} 
              <span className="green-text">{settingData?.brandSubTitle}</span>
            </h3>
          </div>
        </div>
      </div>

      <div className="brand-w">
        <div className="container">
          <div className="slick-list draggable">
            <Slider {...settings}>
              {brandData.map((brand, index) => (
                <div className="col-12" key={index}>
                  <div className="brand-item">
                    <img
                      src={ImageLink(brand.desktopImg)}
                      alt={`Brand ${index + 1}`}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
