import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import axiosInstance from '../axiosInterceptor';
import { toast } from 'react-toastify';
import PreLoader from '../components/PreLoader';
import { getMenuPage } from '../services/getMenuPage';

const Contacts = ({ settingData }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const menuUrl = location.pathname
  const [getData, setGetData] = useState([]);

  const [data, setData] = useState({
    email: '',
    name: '',
    phoneNumber: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    name: '',
    phoneNumber: '',
    message: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await getMenuPage(menuUrl);
      setGetData(response.data);
      setLoading(false);
    };

    fetchData();

  }, [menuUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    if (!data.name.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: 'Name is required'
      }));
      hasErrors = true;
    }
    if (!data.email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Email is required'
      }));
      hasErrors = true;
    }
    if (!data.message) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: 'Message is required'
      }));
      hasErrors = true;
    }
    if (!data.phoneNumber) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: 'Phone number is required'
      }));
      hasErrors = true;
    } else if (!/^\d{10}$/.test(data.phoneNumber)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: 'Please enter a valid 10-digit phone number'
      }));
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }

    try {
      const response = await axiosInstance.post(`contact/createContact`, data);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    }
  };
  
  useEffect(() => {
    document.title = `Mangalam Industries Pvt. Ltd - ${getData.pageId?.title}`;
  },[getData.pageId?.title])

  if (loading) {
    return <PreLoader />;
  }

  return (
    <main>
      <section className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <h2 className="title">{getData.menuName}</h2>
                <h4 dangerouslySetInnerHTML={{ __html: getData.pageId?.content }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-info pb-80 pt-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="sub-title black-text pb-20">leave a message</div>
              <div className="contact-form-wrap">
                <form action="#" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input
                          type="text"
                          name="name"
                          placeholder="Your name"
                          value={data.name}
                          onChange={handleChange}
                        />
                        {errors.name && <p className="text-danger">{errors.name}</p>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input
                          type="number"
                          name="phoneNumber"
                          placeholder="Phone number"
                          value={data.phoneNumber}
                          onChange={handleChange}
                        />
                        {errors.phoneNumber && <p className="text-danger">{errors.phoneNumber}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="form-grp">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your email"
                      value={data.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="text-danger">{errors.email}</p>}
                  </div>
                  <div className="form-grp">
                    <textarea
                      name="message"
                      placeholder="Write message"
                      value={data.message}
                      onChange={handleChange}
                    />
                    {errors.message && <p className="text-danger">{errors.message}</p>}
                  </div>
                  <div className="form-submit">
                    <input type="submit" defaultValue="submit" />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-info-item">
                <div className="sub-title white-text">Head Office</div>
                <p>
                  <i className="fas fa-map-marker-alt" /> Mangalam Industries Pvt.
                  Ltd
                </p>
                <p className="pl-5">
                  {settingData?.headOffice}
                </p>
                <div className="sub-title white-text mt-3">Contact Info</div>
                <ul className="list-wrap">
                  <li>
                    <Link to={`mailto:${settingData?.contactEmail}`}>
                      <i className="far fa-envelope-open" />{" "}
                      {settingData?.contactEmail}
                    </Link>
                  </li>
                  <li>
                    <i className="fas fa-phone-volume" /><span> {settingData?.contactNumber?.split(',').join(' | ')}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="map-area">
        <div className="container-full">
          <div id="contact-map">
            <iframe
              src={settingData?.officeMapUrl}
              width="100%"
              height={450}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="officeMapUrl"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </main>

  )
}

export default Contacts