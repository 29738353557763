import React, { useEffect, useState } from 'react';
import { getCertificate } from '../services/getCertificate';
import { ImageLink } from '../constants';
import Swiper from 'swiper/bundle';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Section6 = () => {
    const [certificates, setCertificates] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getCertificate();
                setCertificates(response.data);
            } catch (error) {
                toast.error('Error fetching certificates:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (certificates.length > 0) {
            const swiper = new Swiper('.certifications-active', {
                slidesPerView: 'auto',
                spaceBetween: 30,
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                breakpoints: {
                    1200: {
                        slidesPerView: 5,
                        spaceBetween: 30,
                    }
                }
            });

            return () => {
                swiper.destroy(true, true);
            };
        }
    }, [certificates]);

    return (
        <section className="project-area project-style-three section-pt-80 section-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="section-title">
                            <span className="sub-title">Mangalam Industries</span>
                            <h2 className="title">Certifications</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="swiper-container certifications-active">
                            <div className="swiper-wrapper">
                                {certificates.map((certificate, index) => (
                                    <div key={index} className="swiper-slide">
                                        <div className="project-item">
                                            <Link target="_blank" to={ImageLink(certificate.desktopPdf)}>
                                                <img src={ImageLink(certificate.desktopImg)} alt="desktopImg" />
                                                <div className="certifi-content">{certificate.title}</div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section6;