import React from 'react'
import { ImageLink } from '../constants';

const Section4 = ({ settingData }) => {
    return (
        <>
            <section className="pt-20 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 text-center">
                            <div className="section-title pb-40">
                                <h2 className="title">Our Culture</h2>
                            </div>
                            <iframe
                                className="img-radius"
                                width="100%"
                                height={600}
                                src={settingData.cultureVideoUrl ?
                                    (settingData.cultureVideoUrl) : ImageLink(settingData.cultureVideo)}
                                title="YouTube video player"
                                frameBorder={0}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen=""
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Section4