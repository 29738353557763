import React, { useEffect, useState } from 'react';
import { getSlider } from '../services/getSlider';
import { ImageLink } from '../constants';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import PreLoader from './PreLoader';
import Section2 from './Section2';
import Section3 from './Section3';
import ProductSection from './ProductSection';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';

const Section1 = ({settingData}) => {
  const [sliderData, setSliderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigator = useNavigate();

  const fetchSlider = async () => {
    const response = await getSlider();
    setSliderData(response.data);
    setLoading(false)
  };

  useEffect(() => {
    fetchSlider();
  }, []);

  const isMobile = () => {
    return window.innerWidth <= 768;
  };

  const settings = {
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    dots: false,
    arrows: false,
  };

  if (loading) {
    return <PreLoader />;
  }

  return (
    <>
    <section className="slider-area">
      {sliderData.map((slider, id) => {
        if (slider.desktopImg.endsWith('.mp4')) {
          return (
            <div className="video-w" key={id}>
              <div className="video-banner-content-center">
                <h1 className=""> {slider.title}</h1>
                <h4>{slider.description}</h4>

                <span onClick={() => { navigator(`/${slider.link}`, { state: slider.link }) }} className="btn transparent-btn">
                  <div className="btn_m">
                    <div className="btn_c">
                      <div className="btn_t1">more about</div>
                      <div className="btn_t2">more about</div>
                    </div>
                  </div>
                </span>
              </div>
              <div className="video-overlay"></div>
              <video className="desktop-video" width="100%" loop autoPlay muted>
                <source src={ImageLink(slider.desktopImg)} type="video/mp4" />
              </video>

              <video className="mobile-video" width="100%" loop autoPlay muted>
                <source src={ImageLink(slider.mobileImg)} type="video/mp4" />
              </video>
            </div>
          );
        } else {
          return (<Slider {...settings}>
            {sliderData.map((slider, id) => {
              return (
                <div key={id}>
                  <div className="single-slider slider-bg"
                    style={{
                      backgroundImage: `url(${isMobile() ? ImageLink(slider.mobileImg) : ImageLink(slider.desktopImg)})`,
                    }}
                  >
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-xl-8">
                          <div className="banner-content text-center">
                            <h2 className="title" style={{ animation: 'fadeInUp 0.5s ease-in-out', animationDelay: '.2s' }}>
                              {slider.title}
                            </h2>
                            <p style={{ animation: 'fadeInUp 0.5s ease-in-out', animationDelay: '.4s' }}>
                              {slider.description}
                            </p>
                            {id !== 0 && <span
                              onClick={() => { navigator(`/${slider.link}`, { state: slider.link }) }}
                              className="btn"
                              style={{ animation: 'fadeInUp 0.5s ease-in-out', animationDelay: '.6s' }}
                            >
                              <div className="btn_m">
                                <div className="btn_c">
                                  <div className="btn_t1">View More</div>
                                  <div className="btn_t2">View More</div>
                                </div>
                              </div>
                            </span>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>)
        }
      })}

      <div className="banner-scroll-down">
        <a href="#about" className="section-link">
          <span />
          <span />
          <span />
        </a>
      </div>

    </section >
      <Section2 />
      <Section3 />
      <ProductSection settingData={settingData} />
      <Section4 settingData={settingData} />
      <Section5 settingData={settingData}/>
      <Section6 />
      </>
  );
};

export default Section1;
