import React, { useEffect, useState } from 'react'
import { getProduct } from '../services/getProduct';
import { ImageLink } from '../constants';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProductSection = ({settingData}) => {
    const setting = {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        arrows: false,
        dots: false,
        Infinity: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };
    const navigator = useNavigate();
    const [getData, setGetData] = useState([]); 

    const fetchData = async () => {
        const response = await getProduct();
        setGetData(response.data)
    };

    useEffect(() => {
        fetchData();
    }, [])
    
    return (
        <>
            <section className="home-projects project-area project-style-three section-pt-80 section-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title">
                                <span className="sub-title">Our Specialization</span>
                                <h2 className="title">Our Products</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <Slider {...setting} className="swiper-container project-active" >
                                {getData.map((item, id) => {
                                    return (
                                        !item.contentId &&
                                        <div className="swiper-slide" key={id}>
                                            <div className="project-item "
                                                onClick={() => {
                                                    navigator(`/${item.link}`, { state: item.link });
                                                }}
                                            >
                                                <div className="project-thumb">
                                                    <Link to="#">
                                                        <img src={ImageLink(item.desktopImg)} alt="product_img" style={{ width: "394px", height: "473px" }} />
                                                    </Link>
                                                </div>
                                                <div className="project-content">
                                                    <h3 className="title">
                                                        <Link to="#" className='ml-3'>{item.title}</Link>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 text-center">
                            <Link target="_blank" to={ImageLink(settingData.uploadBrochure)} className="btn">
                                <div className="btn_m">
                                    <div className="btn_c">
                                        <div className="btn_t1">
                                            <i className="far fa-arrow-alt-circle-down" /> Download Brochure
                                        </div>
                                        <div className="btn_t2">
                                            <i className="far fa-arrow-alt-circle-down" /> Download Brochure
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSection