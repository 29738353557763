import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ImageLink } from '../constants';

const Header = ({ settingData, socialData, menuData }) => {
    const location = useLocation();
    const pathname = location.pathname;
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        document.body.classList.toggle('mobile-menu-visible', !isMenuOpen);
    };

    const isContactsOrAboutUs = pathname.includes('/contacts') || pathname.includes('/about-us') || pathname.includes('/sustainability');

    return (
        <>
            <header className={isContactsOrAboutUs && 'header-style-five'}>
                <div
                    id="sticky-header"
                    className={isContactsOrAboutUs ? "menu-area transparent-header" : "menu-area menu-style-three transparent-header"}
                >
                    <div className="container custom-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mobile-nav-toggler" onClick={toggleMenu}>
                                    <i className="fas fa-bars" />
                                </div>
                                <div className='menu-wrap'>
                                    <nav className="menu-nav add-nav">
                                        <div className="logo">
                                            <Link to="/">
                                                {settingData && settingData.logo ? (
                                                    <img src={ImageLink(settingData.logo)} alt="Logo" />
                                                ) : (
                                                    <img src="/assets/img/logo/logo.png" alt="Logo" />
                                                )}
                                            </Link>
                                        </div>

                                        <div className="navbar-wrap main-menu d-none d-lg-flex">
                                            <ul className="navigation">
                                                {menuData.map((item, id) => {
                                                    return (
                                                        <li key={id}>
                                                            <Link className={isContactsOrAboutUs === true ? "text-black" : "text-white"} to={`/${item.menuUrl}`}>{item.menuName}</Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            
                                <div className={`mobile-menu ${isMenuOpen ? 'show' : ''}`}>
                                    <nav className="menu-box">
                                        <div className="close-btn" onClick={toggleMenu}>
                                            <i className="fas fa-times" />
                                        </div>
                                        <div className="nav-logo" onClick={toggleMenu}>
                                            <Link to="/">
                                                <img src="assets/img/logo/logo.png" alt="Logo" />
                                            </Link>
                                        </div>
                                        <div className="menu-outer">
                                            <ul className="navigation">
                                                {menuData.map((item, id) => {
                                                    return (
                                                        <li key={id} onClick={toggleMenu}>
                                                            <Link to={`/${item.menuUrl}`}>{item.menuName}</Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className="social-links">
                                            <ul className="clearfix list-wrap">
                                                {socialData.map((item, id) => {
                                                    return (
                                                        <li key={id}>
                                                            <Link target='_blank' to={item.url}>
                                                                <i className={item.icon} />
                                                            </Link>
                                                        </li>
                                                    )
                                                })}

                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className="menu-backdrop" onClick={toggleMenu} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header