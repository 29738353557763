import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { ImageLink } from "../constants";

const Section2 = () => {
  const [getData, setGetData] = useState([]);
  const navigator = useNavigate();

  const fetchData = async () => {
    const response = await axiosInstance.get(`content/getHomeContent`);
    const data = response?.data.data;
    setGetData(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section id="about" className="about-area section-pt-80 section-pb-80">
        <div className="container">
          {getData.map((item, id) => {
            return (
              <div
                key={id}
                className="row justify-content-center reverse-mobile"
              >
                {item.desktopImg.endsWith(".mp4") ? (
                  <div className="col-xl-8 col-lg-8">
                    <video
                      className="img-radius home-about-img"
                      width="100%"
                      loop
                      autoPlay
                      muted
                    >
                      <source
                        src={ImageLink(item.desktopImg)}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                ) : (
                  <div className="col-xl-8 col-lg-8">
                    <img src={ImageLink(item.desktopImg)} alt="Logo" />
                  </div>
                )}
                <div className="col-xl-4 col-lg-4">
                  <div className="section-title text-left mb-35">
                    <span className="sub-title">{item.subTitle}</span>
                    <h2 className="about-title">{item.title}</h2>
                  </div>

                  <div className="about-content text-left">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getData[0].description,
                      }}
                    />
                    <span
                      onClick={() => {
                        navigator(`/about-us`, { state: "about-us" });
                      }}
                      className="btn transparent-btn"
                    >
                      <div className="btn_m">
                        <div className="btn_c">
                          <div className="btn_t1">more about</div>
                          <div className="btn_t2">more about</div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Section2;
