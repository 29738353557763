import React from 'react'
import { Link } from 'react-router-dom'

const Footer = ({ settingData, socialData, menuData }) => {
    return (
        <>
            <footer>
                <div className="footer-area footer-bg">
                    <div className="container">
                        <div className="footer-top">
                            <div className="row">
                                <div className="col-xl-4 col-md-4 col-sm-7">
                                    <div className="footer-widget">
                                        <div className="footer-icon">
                                            <i className="far fa-envelope-open" />
                                        </div>
                                        <h2 className="fw-title">EMAIL US</h2>
                                        <div className="footer-link">
                                            <p>
                                                <Link to="mailto:anuj@mangalamindustries.co.in">
                                                    {settingData.contactEmail}
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4 col-sm-5">
                                    <div className="footer-widget">
                                        <div className="footer-icon">
                                            <i className="fas fa-phone-volume" />
                                        </div>
                                        <h2 className="fw-title">CALL US</h2>
                                        <div className="footer-link">
                                            <p>{settingData.contactNumber?.split(',').join(' | ')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-5 col-sm-7">
                                    <div className="footer-widget">
                                        <div className="footer-icon">
                                            <i className="fas fa-map-marker-alt" />
                                        </div>
                                        <h2 className="fw-title">CORPORATE OFFICE</h2>
                                        <div className="footer-link">
                                            <p>
                                                {settingData.headOffice}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="footer-link-h">
                                        <ul>
                                            {menuData.map((item, id) => (
                                                <li key={id} className='text-uppercase'>
                                                    <Link to={item.menuUrl}>{item.menuName}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="copyright-text text-center">
                                        <p>
                                            ©{new Date().getFullYear()} Mangalam Industries Pvt. Ltd
                                            <span className="copyright-space">|</span> Website developed by
                                            :
                                            <Link to="https://www.setblue.com/" target="_blank">
                                                Setblue.com
                                            </Link>
                                        </p>
                                    </div>
                                    <div className="footer-social">
                                        <ul className="list-wrap">
                                            {socialData?.map((item, id) => {
                                                return (
                                                    <li key={id}>
                                                        <Link target='_blank' to={item.url}>
                                                            <i className={item.icon} />
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>

    )
}

export default Footer