import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Yarn from './pages/Yarn';
import Fabrics from './pages/Fabrics';
import MadeUp from './pages/MadeUp';
import Sustainability from './pages/Sustainability';
import Contacts from './pages/Contacts';
import ScrollToTop from './components/ScrollToTop';
import { useEffect, useState } from 'react';
import { getSettings } from './services/getSettings';
import axiosInstance from './axiosInterceptor';
import { getMenu } from './services/getMenu';
import Error from './pages/Error';

function App() {
  const [settingData, setSettingData] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [menuData, setMenuData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getSettings();
      setSettingData(response.data);
    } catch (error) {
      toast.error('Error fetching settings:', error);
    }
  };

  useEffect(() => {
    const fetchSocialMediaData = async () => {
      try {
        const socialMediaResponse = await axiosInstance.get('socialMedia/getSocialMedia');
        setSocialData(socialMediaResponse?.data.data);
      } catch (error) {
        toast.error(error)
      }
    };

    fetchSocialMediaData();
  }, []);

  useEffect(() => {
    fetchData();
    fetchMenuData();
  }, [])

  const fetchMenuData = async () => {
    const response = await getMenu();
    setMenuData(response.data)
  };

  return (
    <Router>
      <ScrollToTop />
      {menuData.length > 0 && <Header settingData={settingData} socialData={socialData} menuData={menuData} />}
      <Routes>
        <Route path="/" element={<Home settingData={settingData} />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/yarn" element={<Yarn />} />
        <Route path="/fabrics" element={<Fabrics />} />
        <Route path="/madeup" element={<MadeUp />} />
        <Route path="/sustainability" element={<Sustainability />} />
        <Route path="/contacts" element={<Contacts settingData={settingData} />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <ToastContainer autoClose={1000} />
      {menuData.length > 0 && <Footer settingData={settingData} menuData={menuData} />}

    </Router>
  );
}

export default App;
