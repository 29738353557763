import React, { useEffect } from 'react'
import Section1 from '../components/Section1'

const Home = ({settingData}) => {
  // useEffect(() => {
  //   document.title = `Mangalam Industries Pvt. Ltd `;
  // },[])
  return (
    <main>
      <Section1 settingData={settingData} />    
    </main>
  )
}

export default Home