import React from 'react'

const PreLoader = () => {
    return (
        <div>
            <div id="preloader">
                <div className="ta-preloader-block">
                    <div className="ta-spinner-eff">
                        <div className="ta-bar ta-bar-top" />
                        <div className="ta-bar ta-bar-right" />
                        <div className="ta-bar ta-bar-bottom" />
                        <div className="ta-bar ta-bar-left" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PreLoader