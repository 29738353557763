import React, { useEffect, useState, useRef } from 'react';
import Odometer from 'odometer';
import { getCounter } from '../services/getCounter';

const Section3 = () => {
    const [counterData, setCounterData] = useState([]);
    const sectionRef = useRef(null);

    const fetchCounterData = async () => {
        const response = await getCounter();
        setCounterData(response.data);
    };

    useEffect(() => {
        fetchCounterData();
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const sectionRefCurrent = sectionRef.current;

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const odometerElements = document.querySelectorAll('.odometer');
                    odometerElements.forEach((element) => {
                        if (!element.odometer) {
                            element.odometer = new Odometer({
                                el: element,
                                value: 0,
                                format: '(.ddd)'
                            });
                        }
                        element.odometer.update(parseInt(element.getAttribute('data-count')));
                    });
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        if (sectionRefCurrent) {
            observer.observe(sectionRefCurrent);
        }

        return () => {
            if (sectionRefCurrent) {
                observer.unobserve(sectionRefCurrent);
            }
        };
    }, [sectionRef]);

    return (
        <div id="counter" className="counter-area section-pt-140 section-pb-110" ref={sectionRef}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12">
                        <div className="counter-item-wrap">
                            <div className="row">
                                {counterData.map((data, index) => (
                                    <div className="col-md-4 col-sm-6 col-6" key={index}>
                                        <div className="counter-item">
                                            <span className={`count odometer ${data.icon === "+" ? "plus" : data.icon === "%" ? "percentage" : ''}`} data-count={data.number}>
                                                {data.number}
                                            </span>
                                            <p>{data.title}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section3;