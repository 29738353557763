import React, { useEffect, useState } from 'react'
import Swiper from 'swiper';
import { getMileStone } from '../services/getMileStone';
import { toast } from 'react-toastify';

const RoadMap = () => {
    const [mileStoneData, setMileStoneData] = useState([]);

    useEffect(() => {
        const fetchMileStoneData = async () => {
            try {
                const response = await getMileStone();
                setMileStoneData(response.data);
            } catch (error) {
                toast.error('Error fetching milestone data:', error);
            }
        };

        fetchMileStoneData();
    }, []);

    useEffect(() => {
        if (mileStoneData.length > 0) {
            const swiper = new Swiper('.roadmap-active', {
                slidesPerView: 'auto',
                spaceBetween: 24,
                loop: true,
                autoplay: {
                    delay: 5000,
                },
            });

            return () => {
                swiper.destroy(true, true);
            };
        }
    }, [mileStoneData]);

    return (
        <>
            <section className="roadmap-area pt-80 pb-80">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="section-title text-center mb-50">
                                <span className="sub-title">A Journey of Achievements</span>
                                <h2 className="title">Our Milestone</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-0">
                    <div className="col-lg-12">
                        <div className="roadmap-wrap">
                            <div className="swiper-container roadmap-active">
                                <div className="swiper-wrapper">
                                    {mileStoneData.map((item, id) => {
                                        return (
                                            <div className="swiper-slide" key={id} >
                                                <div className="roadmap-item">
                                                    <h4 className="title">{item.title}</h4>
                                                    <div className="roadmap-content">
                                                        <span className="dot" />
                                                        <p>
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RoadMap