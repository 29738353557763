import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { getMenuPage } from '../services/getMenuPage';
import { ImageLink } from '../constants';
import PreLoader from '../components/PreLoader';

const Sustainability = () => {
    const location = useLocation();
    const menuUrl = location.pathname
    const [getData, setGetData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getMenuPage(menuUrl);
            setGetData(response.data);
            setLoading(false);
        };

        fetchData();

    }, [menuUrl]);

    useEffect(() => {
        document.title = `Mangalam Industries Pvt. Ltd - ${getData.pageId?.title}`;
      },[getData.pageId?.title])
      
    if (loading) {
        return <PreLoader />;
    }
    return (
        <>
            <main>
                <section className="breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-content">
                                    <h2 className="title">{getData.pageId?.title}</h2>
                                    <h4 dangerouslySetInnerHTML={{ __html: getData.pageId?.content }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fabrics-area pt-80 pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mx-auto">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <img
                                            className="img-radius w-100 about-img"
                                            src="assets/img/sustainability/main1.jpg"
                                            alt='about_img1'
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <img
                                            className="img-radius w-100 about-img"
                                            src="assets/img/sustainability/main2.jpg"
                                            alt='about_img2'
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <img
                                            className="img-radius w-100 about-img"
                                            src="assets/img/sustainability/main3.jpg"
                                            alt='about_img3'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {getData.pageId?.contentData?.map((item, id) => {
                    if (item.position === 3 || item.position === 4) {
                        return null;
                    }
                    return (
                        <section key={id} className={`sustainability-area pt-0 pb-80`}>
                            <div className="container">
                                <div className='row justify-content-center align-items-center'>
                                    {id % 2 === 0 && id !== getData.pageId.contentData.length - 1 &&
                                        <React.Fragment>
                                            <div className="col-lg-6">
                                                <div>
                                                    <div className="section-title mb-20">
                                                        <h2 className="inn-sub-title">{item.title}</h2>
                                                    </div>
                                                    <p dangerouslySetInnerHTML={{ __html: item.description }} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                {item.desktopImg.endsWith('.mp4') ?
                                                    <video
                                                        className="sb-img img-radius"
                                                        width="100%"
                                                        loop
                                                        autoPlay
                                                        muted
                                                    >
                                                        <source src={ImageLink(item.desktopImg)} type="video/mp4" />
                                                    </video>
                                                    :
                                                    <img
                                                        className="w-100 about-img img-radius"
                                                        src="assets/img/sustainability/solar.jpg"
                                                        alt="solar_img"
                                                    />
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                    {id % 2 !== 0 && id !== getData.pageId.contentData.length - 1 &&
                                        <React.Fragment>
                                            <div className="col-lg-6">
                                                <img
                                                    className="w-100 about-img img-radius"
                                                    src="assets/img/sustainability/solar.jpg"
                                                    alt="solar_img"
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <div>
                                                    <div className="section-title mb-20">
                                                        <h2 className="inn-sub-title">{item.title}</h2>
                                                    </div>
                                                    <p dangerouslySetInnerHTML={{ __html: item.description }} />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {id === getData.pageId.contentData.length - 1 &&
                                        <div className="col-lg-6 offset-lg-3">
                                            <div>
                                                <div className="section-title mb-20">
                                                    <h2 className="inn-sub-title">{item.title}</h2>
                                                </div>
                                                <p dangerouslySetInnerHTML={{ __html: item.description }} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </section>
                    );
                })}
                {getData.pageId?.contentData?.map((item, id) => {
                    if (item.position === 1 || item.position === 2) {
                        return null;
                    }
                    const isEvenRow = id % 2 === 0;
                    return (
                        <React.Fragment key={id}>
                            <section className="sustainability-area pt-0 pb-80">
                                <div className="container">
                                    <div className="row justify-content-center align-items-center">
                                        <div className={`col-lg-6 order-${isEvenRow ? '1' : '2'}`}>
                                            <div className={item.title === "Water" ? "order-1 water-box" : "order-2 nature-box"} >
                                                <div className="section-title mb-35">
                                                    <h2 className="title">
                                                        <img
                                                            src={item.title === "Water" ? "assets/img/sustainability/waterdrop.png" : "assets/img/sustainability/nature.png"}
                                                            alt={item.title}
                                                        />
                                                        {item.title}
                                                    </h2>
                                                </div>
                                                <p dangerouslySetInnerHTML={{ __html: item.description }} />
                                            </div>
                                        </div>
                                        <div className={`col-lg-6 order-${isEvenRow ? '2' : '1'}`}>
                                            <img
                                                className="w-100 img-radius"
                                                src={ImageLink(item.desktopImg)}
                                                alt={item.title}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </section></React.Fragment>
                    )
                })}
                <section className="sustainability-area pt-0 pb-0">
                    <img
                        className="w-100 desktop-video"
                        src="assets/img/sustainability/future-plans-d.jpg"
                        alt='future_plan_img'
                    />
                    <img
                        className="w-100 mobile-video"
                        src="assets/img/sustainability/future-plans-m.jpg"
                        alt='future_plan_img'
                    />
                </section>
                <section className="sustainability-area-bg pb-80 pt-80">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <div className="sustain-box">
                                    <img
                                        className="mb-10"
                                        src="assets/img/sustainability/futureplan/loss.png"
                                        alt='reduse_img'
                                    />
                                    <h5>Reduse</h5>
                                    <p>
                                        Reduce- Currently the RO treatment plant refuses 40% of the
                                        water before it is even used. We have planned to set up a water
                                        treatment mechanism that breaks down large micros from the
                                        refuse water and thereby the refuse water can be completely
                                        recycled by 2022.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="sustain-box">
                                    <img
                                        className="mb-10"
                                        src="assets/img/sustainability/futureplan/recycle.png"
                                        alt='reuse_img'
                                    />
                                    <h5>Reuse</h5>
                                    <p>
                                        We have planned to switch more than 20% of our internal yarn
                                        need to plastic tubes from paper tubes, leading to more than 100
                                        times reuse.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="sustain-box">
                                    <img
                                        className="mb-10"
                                        src="assets/img/sustainability/futureplan/recycle-sign.png"
                                        alt='recycle_img'
                                    />
                                    <h5>Recycling</h5>
                                    <p>
                                        With 25% y-o-y growth in production capacity, we are committed
                                        to recycling each drop of extra water used in our facility.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>

    )
}

export default Sustainability